<template>
  <v-card max-width="400" class="mx-auto">
    <v-app-bar color="white">
      <v-icon class="mr-3">mdi-select-inverse</v-icon>
      <v-toolbar-title>เลือกงวด</v-toolbar-title>
      <v-spacer></v-spacer>
      {{ HostID }} {{ LineID }} {{ AgentID }}
    </v-app-bar>

    <v-container>
      <v-row dense>
        <!-- <v-col cols="12">
          <v-card
            color="deep-orange darken-4
"
            dark
          >
            <v-card-title class="text-h5 justify-center"> ประกาศ </v-card-title>

            <v-card-text class="white--text" style="font-size: 4.8vw"
              >อะไรสักอย่าง</v-card-text
            >
          </v-card>
        </v-col> -->

        <v-col v-for="(item, i) in period_items" :key="i" cols="12">
          <v-card :color="getBGColor(item.IsDefault)">
            <div>
              <v-card-title
                class="text-h6 justify-center"
                style="font-weight: bold; color: Salmon"
                >งวด {{ getAbbrPeriodThai(item.PeriodDT) }}
                {{ StatusText(item.IsDefault) }}</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text style="font-size: 4.8vw">
                <v-row>
                  <v-col cols="5" class="text-right">รางวัลที่ 1 : </v-col>
                  <v-col cols="7" class="my-2 py-0 text-left">
                    <v-chip
                      class="ma-0"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 95%"
                    >
                      {{ item.FirstPrize }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="5" class="text-right">เลขหน้า 3 ตัว : </v-col>
                  <v-col cols="7" class="my-2 py-0 text-left">
                    <v-chip
                      class="ma-0"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 45%"
                    >
                      {{ item.FourTime1 }} </v-chip
                    ><v-chip
                      class="ma-0 ml-2"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 45%"
                    >
                      {{ item.FourTime2 }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="5" class="text-right">เลขท้าย 3 ตัว : </v-col>
                  <v-col cols="7" class="my-2 py-0 text-left">
                    <v-chip
                      class="ma-0"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 45%"
                    >
                      {{ item.FourTime3 }} </v-chip
                    ><v-chip
                      class="ma-0 ml-2"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 45%"
                    >
                      {{ item.FourTime4 }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="5" class="text-right">เลขท้าย 2 ตัว : </v-col>
                  <v-col cols="7" class="my-2 py-0 text-left">
                    <v-chip
                      class="ma-0"
                      label
                      color="orange darken-4"
                      outlined
                      style="width: 95%"
                    >
                      {{ item.LastTwo }}
                    </v-chip></v-col
                  >
                </v-row>
              </v-card-text>

              <v-card-actions>
                <!-- <v-spacer></v-spacer> -->
                <v-btn
                  outlined
                  block
                  class="blue-grey lighten-4 black--text"
                  @click="OnClickSelectPeriod(item.PeriodDT)"
                >
                  เลือก <v-icon>mdi-play</v-icon>
                </v-btn>
                <!-- <v-col class="text-right">
                  <v-btn text> ตกลง </v-btn>
                </v-col> -->
              </v-card-actions>
            </div>

            <!-- <v-avatar class="ma-3" size="125" tile>
                <v-img :src="item.src"></v-img>
              </v-avatar> -->
          </v-card>
        </v-col>

        <v-col>
          <v-card color="deep-orange darken-4" dark>
            <div>
              <v-card-title class="text-h5 justify-center">ทดลอง</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="text-center" style="font-size: 4.8vw"
                >สามารถเข้าไปทดลองคีย์ได้</v-card-text
              >

              <v-card-actions>
                <!-- <v-spacer></v-spacer> -->
                <v-btn
                  @click="OnClickSelectPeriod(test_period.PeriodDT)"
                  outlined
                  class="error"
                  block
                >
                  เลือก <v-icon>mdi-play</v-icon>
                </v-btn>
                <!-- <v-col class="text-right">
                  <v-btn text> ตกลง </v-btn>
                </v-col> -->
              </v-card-actions>
            </div>

            <!-- <v-avatar class="ma-3" size="125" tile>
                <v-img :src="item.src"></v-img>
              </v-avatar> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
export default {
  name: "SelectPeriod",
  mixins: [globalFunctionMixin],
  components: {},
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    this.fetch_data();
  },
  methods: {
    async fetch_data() {
      let corangeentials = {
        to: "Dashboard",
        method: "get_period",
      };
      await this.$store.dispatch("dashboard/TransferData", corangeentials).then(
        (response) => {
          this.period_items = response.PeriodList;
          this.test_period = response.PeriodTest;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    OnClickSelectPeriod(p_PeriodDT) {
      this.$router.push("/Dashboard");
      sessionStorage.setItem("PeriodDT", p_PeriodDT);
    },
    GetCloseTime() {
      if (this.CloseDT == "") return "";
      const arrCloseDT = this.CloseDT.split(" ");
      const arrCloseTime = arrCloseDT[1].split(":");

      var CloseDate = "";
      //ถ้าวันที่ปิดไม่ตรงกับวันหวยออก
      if (arrCloseDT[0] != this.PeriodDT) {
        CloseDate = this.getAbbrPeriodThai(arrCloseDT[0]) + " เวลา ";
      }

      return CloseDate + arrCloseTime[0] + ":" + arrCloseTime[1];
    },
    StatusText(p_IsDefault) {
      if (p_IsDefault == 1) return "(งวดปัจจุบัน)";
      return "(งวดเก่า)";
    },
    getBGColor(p_IsDefault) {
      if (p_IsDefault == 1) return "green lighten-5";
      return "blue-grey lighten-5";
    },
  },

  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),

    period_items: [],
    test_period: [],
  }),
};
</script>

<style></style>
